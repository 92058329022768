import React, { useState, useEffect, useMemo } from "react";
import { Box, Grid, Collapse, Modal } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ViewOption from "../components/ViewOption";
import Filter from "../components/Filter";
import AddParticipantModal from "../components/Participant/AddParticipantModal";
import EditParticipantModal from "../components/Participant/EditParticipantModal";
import HeavyTable from "../components/table/HeavyTable";
import DeleteModal from "../components/modal/DeleteModal";
import ExportParticipantModal from "../components/Participant/ExportParticipantModal";
import {
  makeSureIsArray,
  makeOptions,
  getDateDiff,
  changeViewOptions,
  changeRowsPerPage,
  changeFiltered,
  changeSearch,
  loadData,
  controlView,
  controlFilter,
  cancelAction,
} from "../functions/general";
import { showWarningSwal } from "../functions/alert";
import { getParticipants } from "../functions/getData";
import { deleteParticipant } from "../functions/deleteData";
import { showNotification } from "../functions/snackbar";
import NunitoText from "../components/general/NunitoText";
import ReusableTextField from "../components/general/ReusableTextField";
import ReusableButton from "../components/general/ReusableButton";
import ViewAndFilterButton from "../components/ViewAndFilterButton";
import LoadingBackdrop from "../components/general/LoadingBackdrop";
import NoData from "../components/general/NoData";
import Loader from "../components/general/Loader";
import { useSnackbar } from "notistack";
import { isAdmin } from "../functions/checkrole";
import FilterWrapper from "../components/general/FiltersWrapper";
const participantHeader = () =>
  [
    { id: "name", label: "Name" },
    { id: "index_no", label: "Index" },
    isAdmin() && { id: "country_name", label: "Country" },
    { id: "identifier", label: "Identifier" },
    { id: "is_answers_uploaded", label: "Answers Uploaded" },
    { id: "email", label: "email" },
    { id: "status", label: "Status" },
    { id: "private", label: "Private" },
    { id: "competition_name", label: "Competition/Assessment" },
    { id: "school_name", label: "School" },
    { id: "tuition_centre_name", label: "Tuition Centre" },
    { id: "grade", label: "Grade" },
    { id: "class", label: "Class" },
    { id: "certificate_no", label: "Certificate No" },
    { id: "award", label: "Award" },
    { id: "created_by", label: "Created By" },
    { id: "last_modified_by", label: "Last Modified By" },
  ].filter(Boolean);
const initialParams = "?limits=10";
export default function EnhancedParticipants({ setPartcipient }) {
  document.title = "Participant";
  const [header, setHeader] = useState(participantHeader());
  const [participantTable, setParticipantTable] = useState();
  const [filtered, setFiltered] = useState(
    [
      isAdmin() && {
        label: "Organization",
        state: "",
        key: "organization_id",
        header: "organization",
      },
      isAdmin() && {
        label: "Country",
        state: "",
        key: "country_id",
        header: "countries",
      },
      {
        label: "Competition",
        state: "",
        key: "competition_id",
        header: "competition",
      },
      { label: "Grade", state: "", key: "grade", header: "grade" },
      { label: "Status", state: "", key: "status", header: "status" },
      { label: "Private", state: "", key: "private", header: "private" },
      { label: "School", state: "", key: "school_id", header: "schools" },
    ].filter(Boolean)
  );
  const [viewOptions, setViewOptions] = useState(
    [
      isAdmin() && { label: "Country", state: true, key: "country_name" },
      { label: "Competition/Assessment", state: true, key: "competition_name" },
      { label: "School", state: true, key: "school_name" },
      { label: "Tuition Centre", state: true, key: "tuition_centre_name" },
      { label: "Grade", state: true, key: "grade" },
      { label: "Class", state: true, key: "class" },
      { label: "Partner", state: true, key: "partner_name" },
      { label: "Created By", state: true, key: "created_by" },
      { label: "Last Modified By", state: true, key: "last_modified_by" },
      { label: "Status", state: true, key: "status" },
    ].filter(Boolean)
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [params, setParams] = useState(initialParams);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [filtering, setFiltering] = useState(false);
  const [id, setId] = useState("");
  const [selected, setSelected] = useState([]);
  const [selecting, setSelecting] = useState([]);
  const [onDelete, setOnDelete] = useState(false);
  const [onAddParticipant, setOnAddParticipant] = useState(false);
  const [onEditParticipant, setOnEditParticipant] = useState(false);
  const [onExport, setOnExport] = useState(false);
  const [country,setCountry] = useState('')
  const [school,setSchool] = useState('')
  const [grade,setGrade] = useState('')
  const [status,setStatus] = useState('')
  const [competition,setCompetition] = useState('')
  const [organization,setOrganization] = useState('')
  const [privat,setPrivat] = useState('')
  const handleView = () => controlView(viewing, setViewing, setFiltering);
  const handleFilter = () => controlFilter(filtering, setFiltering, setViewing);
  const cancelDelete = () => cancelAction(setOnDelete, setId);
  const cancelEdit = () => cancelAction(setOnEditParticipant, setId);
  const cancelExport = () => cancelAction(setOnExport);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const afterFunction = (cancel, data) => {
    if (data.status === 200) {
      getData(initialParams);
      cancel();
      showNotification("success", data.message, enqueueSnackbar, closeSnackbar);
      setSelected([]);
      setSelecting([]);
    }
  };
  const deleteFunction = () => {
    deleteParticipant({ id: id === "" ? selected : [id] })
      .then((d) => {
        afterFunction(cancelDelete, d);
      })
      .catch((e) => console.log(e));
  };

  const adjustEndpoint = (endpoint , value)=>{
    let initalEndpoint = endpoint
    if(value!==country&&country!==''){
      initalEndpoint+=`&country=${country}`
    }
    if(value!==school&&school!==''){
      initalEndpoint+=`&school=${school}`
    }
    if(value!==grade&&grade!==''){
      initalEndpoint+=`&grade=${grade}`
    }
    if(value!==status&&status!==''){
      initalEndpoint+=`&status=${status}`
    }
    if(value!==organization&&organization!==''){
        initalEndpoint+=`&organization=${organization}`
      }
      if(value!==competition&&competition!==''){
        initalEndpoint+=`&competition=${competition}`
      }
      if(value!==privat&&privat!==''){
        initalEndpoint+=`&private=${privat}`
      }
   return initalEndpoint

  }
const url = 'participant/list?get_filter='
  const filters =[{
    label:"country",
    endpoint:`${url}country`,
    value:country,
    onChange:setCountry,
    key:'country',
    setValue:setCountry
  },{
    label:"school",
    endpoint:`${url}school`,
    value:school,
    onChange:setSchool,
    key:'school',
    setValue:setSchool
  },
  {
    label:"grade",
    endpoint:`${url}grade`,
    value:grade,
    onChange:setGrade,
    key:'grade',
    setValue:setGrade
  },
  {
    label:"Status",
    endpoint:`${url}status`,
    value:status,
    onChange:setStatus,
    key:'status',
    setValue:setStatus
  },
  {
    label:"competition",
    endpoint:`${url}competition`,
    value:competition,
    onChange:setCompetition,
    key:'competition',
    setValue:setCompetition
  },
  {
    label:"organization",
    endpoint:`${url}organization`,
    value:organization,
    onChange:setOrganization,
    key:'organization',
    setValue:setOrganization
  },
  {
    label:"private",
    endpoint:`${url}private`,
    value:privat,
    onChange:setPrivat,
    key:'private',
    setValue:setPrivat
  }]

  const formatTableData = (data)=>{
   let formattedData = data.map(el=>{
      return  {
        id:el.id,
        name:el.name,
        competition_id:el.competition_organization?.competition_id,
        organization_name:el.organization?.name,
        country_id:el.country?.id,
        school_id:el.school?.id,
        index_no:el.index_no,
        identifier:el.identifier,
        is_answers_uploaded:el.is_answers_uploaded===true?'Yes':'NO',
        country_name:el.country?.name,
        email:el.email,
        status:el.iac_status,
        private:el.private,
        competition_name:el.competition_organization?.competition.alias,
        school_name:el.school?.name,
        tuition_centre_name:el.tuition_centre?.name,
        grade:el.grade,
        class:el.class,
        certificate_no:el.certificate_no,
        award:el.result?.award,
        created_by:el.created_by,
        last_modified_by:el.last_modified_by
      }
    })
    return formattedData
  }
  const getData = (params, signal) => {
    setSelected([]);
    setSelecting([]);
    setLoading(true);
    let param=params+''
    if(search!=='') param+=`search=${search}`
    filters.map((filter)=>{
        if(filter.value!=='') param+=`&${filter.key}=${filter.value}`
    })
    
    getParticipants(`${param}` , signal).then(res=>{
      res.data = formatTableData(res.data)
       setParticipantTable(res)
       setLoading(false)
    })
  };
  console.log(participantTable)
  const onChangeViewOptions = (value) =>
    changeViewOptions(participantHeader(), setViewOptions, setHeader, value);
  const onChangeRowsPerPage = (value) => {
    changeRowsPerPage(
      value,
      rowsPerPage,
      filtered,
      search,
      signal,
      setRowsPerPage,
      setParams,
      getData
    );
  };
  const onChangeFiltered = (value) => {
    changeFiltered(
      value,
      filtered,
      search,
      rowsPerPage,
      signal,
      setFiltered,
      setParams,
      getData
    );
  };
  useEffect(()=>{
    getData(params, signal)
      },[country, status,grade,status,competition,organization,privat])
  const onChangeSearch = (value) => {
    setSearch(value)
    if (JSON.stringify(value) === JSON.stringify(search)) return console.log('Search is unchanged')
        if(value!==''){
    getData(params+`&search=${value}` , signal)}else{
        getData(params , signal)
    }
  };
  const onChangePage = (pageNo) => getData(`${params}&page=${pageNo}`, signal);

  const controller = useMemo(() => new AbortController(), []);
  const signal = controller.signal;
  useEffect(() => {
    getData(initialParams, signal);
    return () => controller.abort();
  }, [controller, signal]);
  const checkIfAllSelectedRowsCanBeDeleted = (selected, data) => {
    if (selected.length === 0) return false;
    for (let i = 0; i < selected.length; i++) {
      let row = makeSureIsArray(data).find((d) => d.id === selected[i]);
      if (getDateDiff(new Date(), new Date(row.created_at)) > 7) return false;
    }
    return true;
  };
  const onClickMassDelete = () => {
    if (!selected.length)
      return showWarningSwal(
        "Please select at least one participant to mass delete"
      );
    if (isAdmin()) return setOnDelete(true);
    if (
      !isAdmin() &&
      checkIfAllSelectedRowsCanBeDeleted(selected, participantTable.data)
    )
      return setOnDelete(true);
    showWarningSwal(
      "You can only delete particpants that are created less than 7 days ago"
    );
  };
  console.log(participantTable)
  return (
    <Box className="wrapperBox">
      <LoadingBackdrop loading={loading} />
      <Collapse in={viewing}>
        <ViewOption
          viewOptions={viewOptions}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangeViewOptions={onChangeViewOptions}
        />
      </Collapse>
      <Collapse in={filtering}>
      <FilterWrapper
        filters={filters}
        adjustEndpoint={adjustEndpoint}
        />
      </Collapse>
      <Grid
        className="firstRowContainer"
        container
        justifyContent="space-between"
      >
        <div className="dashboardAndSelfBtnDiv">
          <ReusableButton
            text="Dashboard"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            width={125}
            br={18}
            to="/dashboard"
            iconType="home"
          />
          <ChevronRightIcon />
          <ReusableButton
            text="Participants"
            fontSize={14}
            bgColor="#F16774"
            height={36}
            br={18}
          />
        </div>
        <div className="viewAndFilterBtnDiv">
          <ViewAndFilterButton
            text="View Options"
            state={viewing}
            fontSize={14}
            height={42}
            onClick={handleView}
            marginRight={10}
          />
          <ViewAndFilterButton
            text="Filter"
            state={filtering}
            fontSize={14}
            height={42}
            onClick={handleFilter}
          />
        </div>
      </Grid>
      <Grid className="tableContainer" container>
        <NunitoText
          value="Participants"
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
        />
        <Grid
          className="searchAndBtnContainer"
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <ReusableTextField
            type="search"
            width={500}
            height={60}
            bgColor="#F2F2F2"
            placeholder="Search using keyword"
            state={search}
            setState={onChangeSearch}
            onBlur
          />
          <div>
            {/* <CSVButtons filename='participant-for-ocs' buttonText='Export CSV For OCS'
                            onClick={() => showWarningSwal('Select a participant to export')} data={csvDataForOCS()} />
                        <CSVButtons filename='participants' buttonText='Export CSV'
                            onClick={() => showWarningSwal('Select a participant to export')} data={csvData()} /> */}
            <ReusableButton
              text="Export CSV"
              fontSize={15}
              bgColor="#5E75C3"
              height={46}
              marginRight={20}
              iconType="export"
              onClick={() => setOnExport(true)}
            />
            <ReusableButton
              text="Import CSV"
              fontSize={15}
              bgColor="#5E75C3"
              height={46}
              marginRight={20}
              iconType="import"
              to="csvupload?upload=participant"
            />
          </div>
          <div className="endBtnDiv">
            <ReusableButton
              text="Add Participant"
              fontSize={15}
              bgColor="#5E75C3"
              height={48}
              marginRight={20}
              onClick={() => setOnAddParticipant(true)}
              iconType="add"
            />
            <ReusableButton
              text="Delete"
              fontSize={15}
              bgColor="#E83042"
              height={48}
              marginRight={20}
              onClick={() => onClickMassDelete()}
              iconType="delete"
            />
          </div>
        </Grid>
        <NunitoText
          value='Search only in "Name", "Index", "School" and "Tuition Centre" column'
          fontSize={20}
          fontWeight={400}
          italic
          color="#144A94"
        />
        {/* {JSON.stringify(forms)}
                {competitionOptions && countryOptions && schoolOptions && userOptions && forms.map((form, index) => (
                    <AddParticipantForm key={index} count={index} afterAddingParticipant={getData}
                        competitionOptions={competitionOptions} countryOptions={countryOptions} schoolOptions={schoolOptions}
                        userOptions={userOptions} forms={forms} setForms={setForms} form={form} />
                ))} */}
        {loadingTable && <Loader height={600} />}
        {!loadingTable &&
          participantTable &&
          makeSureIsArray(participantTable).length && (
            <HeavyTable
              headers={header}
              list={participantTable}
              title="participants"
              rowsPerPage={rowsPerPage}
              setPartcipient={setPartcipient}
              setOnDelete={setOnDelete}
              onChangePage={onChangePage}
              fixed={["name", "index_no", "country_name"]}
              selecting={selecting}
              setSelecting={setSelecting}
              selected={selected}
              setSelected={setSelected}
              setId={setId}
              setOnEdit={setOnEditParticipant}
            />
          )}
        {!loadingTable &&
          firstLoaded &&
          participantTable &&
          !makeSureIsArray(participantTable).length && (
            <NoData height={600} />
          )}
      </Grid>
      <Modal open={onDelete} onClose={() => cancelDelete()}>
        <>
          {Boolean(participantTable) && (
            <DeleteModal
              table={participantTable}
              id={id}
              selected={selected}
              deleteFunction={deleteFunction}
              cancelDelete={cancelDelete}
            />
          )}
        </>
      </Modal>
      <Modal open={onAddParticipant} onClose={() => setOnAddParticipant(false)}>
        <>
          <AddParticipantModal
            setLoading={setLoading}
            setOnAddParticipant={setOnAddParticipant}
            afterAddingParticipant={afterFunction}
          />
        </>
      </Modal>
      <Modal open={onEditParticipant} onClose={() => cancelEdit()}>
        <>
          {participantTable && (
            <EditParticipantModal
              setLoading={setLoading}
              table={participantTable}
              id={id}
              afterEditingParticipant={afterFunction}
              cancelEdit={cancelEdit}
            />
          )}
        </>
      </Modal>
      <Modal open={onExport} onClose={() => cancelExport()}>
        <>
          <ExportParticipantModal
          formatTableData={formatTableData}
            selected={selected}
            setSelected={setSelected}
            setSelecting={setSelecting}
            partData={participantTable}
            cancelExport={cancelExport}
          />
        </>
      </Modal>
    </Box>
  );
}
